import { defineStyleConfig } from '@chakra-ui/react'
import { COLORS } from '@ds/tokens/colors'

export enum BadgeVariant {
	'primary' = 'primary',
	'secondary' = 'secondary',
}

export const badgeTheme = defineStyleConfig({
	baseStyle: {
		border: '1px solid',
		borderRadius: '6px',
		fontSize: '12px',
		fontWeight: 500,
		textTransform: 'none',
		padding: '4px 8px',
	},
	variants: {
		[BadgeVariant.primary]: {
			borderColor: COLORS.purple[5],
			bg: COLORS.purple[2],
			color: COLORS.purple[9],
		},
		[BadgeVariant.secondary]: {
			display: 'flex',
			alignItems: 'center',
			gap: 1,
			borderColor: COLORS.background[10],
			bg: 'transparent',
			color: COLORS.background[4],
		},
	},
})
