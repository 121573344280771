import { ComponentStyleConfig } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'

export const Tooltip: ComponentStyleConfig = {
	baseStyle: {
		bg: COLORS.background[2],
		color: ColorTokens.white,
		borderRadius: '8px',
		p: '6px 12px',
	},
}
