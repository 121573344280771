import { ComponentStyleConfig } from '@chakra-ui/react'
import { COLORS, ColorTokens } from '@ds/tokens/colors'

import { SHADOWS } from '../constants'
import { SPACING } from '../space'
import { FONT_SIZES, WEIGHT } from '../typography'
import { Radii } from './../borders'

export enum INPUT_ENUM {
	base = 'base',
	secondary = 'secondary',
}

const components = {
	Switch: {
		baseStyle: () => ({
			track: {
				_checked: {
					bg: ColorTokens.accent,
				},
			},
		}),
	},
	Tooltip: {
		baseStyle: () => {
			return {
				bg: COLORS.background[3],
				color: ColorTokens.white,
			}
		},
	},
	Radio: {
		baseStyle: () => ({
			control: {
				w: SPACING[5],
				h: SPACING[5],

				bg: ColorTokens.white,
				borderRadius: 'full',
				color: COLORS.purple[9],

				boxShadow: '0px 1px 4px rgba(34, 43, 72, 0.08)',
				border: '1px solid',
				borderColor: COLORS.gray[7],

				_hover: {
					borderColor: COLORS.purple[9],
					boxShadow: '0px 1px 4px rgba(34, 43, 72, 0.08)',
				},

				_focusVisible: {
					boxShadow:
						'0px 0px 0px 1px #FFFFFF, 0px 0px 0px 4px rgba(99, 99, 242, 0.32)',
					borderColor: COLORS.purple[9],
				},

				_checked: {
					bg: 'white',
					border: '2px solid',
					borderColor: COLORS.purple[9],
					color: COLORS.purple[9],
					innerWidth: '100%',
					innerHeight: '100%',

					_before: {
						height: '8px',
						width: '8px',
						borderRadius: 'full',
					},

					_hover: {
						bg: 'white',
						border: '2px solid',
						borderColor: COLORS.purple[9],
						color: COLORS.purple[9],
					},

					_focus: {
						bg: 'white',
						border: '2px solid',
						borderColor: COLORS.purple[9],
						color: COLORS.purple[9],
					},
				},
			},
		}),
	},
	Popover: {
		baseStyle: () => ({
			content: {
				bg: ColorTokens.white,
				borderRadius: '10px',
				_focus: {
					boxShadow: '0px 1px 6px rgba(12, 17, 61, 0.1)',
				},
			},
		}),
	},
	Input: {
		variants: {
			[INPUT_ENUM.base]: () => ({
				field: {
					height: SPACING[10],
					bg: COLORS.background[12],
					color: COLORS.gray[12],
					fontWeight: WEIGHT.medium,
					fontSize: FONT_SIZES.sm,
					lineHeight: SPACING[5],
					borderRadius: Radii.lg,
					px: '12px',
					py: '10px',

					border: '1px solid',
					borderColor: COLORS.gray[4],

					_hover: {
						borderColor: COLORS.background[9],
					},
					_focus: { boxShadow: SHADOWS.focus, zIndex: 5 },

					_disabled: {
						bg: COLORS.background[11],
						borderColor: COLORS.gray[4],
						color: COLORS.gray[12],
					},
				},
			}),
			[INPUT_ENUM.secondary]: () => ({
				field: {
					height: 9,
					color: 'text',
					background: COLORS.gray[2],
					px: 3,
					borderRadius: 'md',
					borderWidth: '1px',
					borderColor: ColorTokens.black,
					fontWeight: 400,
					fontSize: 'sm',
					_focus: {
						boxShadow: 'focus',
					},
				},
			}),
		},
		defaultProps: {
			size: null,
			variant: INPUT_ENUM.base,
		},
	} as ComponentStyleConfig,
	Textarea: {
		baseStyle: () => ({
			color: 'text',
			background: COLORS.background[12],
			px: 3,
			borderRadius: 'md',
			borderWidth: '1px',
			borderColor: ColorTokens.border_primary,
			fontWeight: 400,
			fontSize: 'sm',
			_focus: {
				boxShadow: 'focus',
			},
		}),
		defaultProps: {
			size: null,
		},
	},
	Modal: {
		baseStyle: {
			header: {
				bg: ColorTokens.white,
				borderRadius: '8px 8px 0 0',
				borderBottomWidth: 'medium',
				p: 5,
				display: 'flex',
				align: 'center',
				borderColor: ColorTokens.border_primary,
				fontSize: '16px',
			},
			body: {
				bg: ColorTokens.white,
				borderRadius: Radii.md,
				p: 5,
			},
			closeButton: {
				top: 4,
				right: 5,
			},
			footer: {
				bg: ColorTokens.white,
				borderRadius: '0 0 8px 8px',
				borderTopWidth: 'medium',
				display: 'flex',
				alignContent: 'center',
				borderColor: ColorTokens.border_primary,
				p: 5,
			},
		},
	},
}

export default components
