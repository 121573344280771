import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { addTwoWeeks, MeetingStatus } from 'shared-utils'

import { Meeting } from '../types'
import { NewMeetingLeadType } from '../types/meeting-handoff'

interface MeetingsHomeState {
	dateFilterStart?: Date
	dateFilterEnd?: Date
	participantIds?: string[]
	participantId?: string | null
	status?: MeetingStatus | null
	isHandoffModalOpen: boolean
	handoffMeeting: Meeting | null
	newHandoffMeeting: Meeting | null
	newMeetingLead: NewMeetingLeadType | null
	handoffTimezone: string
	selectedMeeting?: Meeting | null
}

const initialState: MeetingsHomeState = {
	dateFilterStart: new Date(),
	dateFilterEnd: addTwoWeeks(new Date()),
	participantIds: [],
	participantId: null,
	status: null,
	isHandoffModalOpen: false,
	handoffMeeting: null,
	newHandoffMeeting: null,
	newMeetingLead: null,
	handoffTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
}

export const meetingsHomeSlice = createSlice({
	name: 'meetings',
	initialState,
	reducers: {
		setSelectedMeeting: (state, { payload }: PayloadAction<Meeting | null>) => {
			state.selectedMeeting = payload
		},
		setDateFilterStart: (state, { payload }: PayloadAction<Date>) => {
			state.dateFilterStart = payload
		},
		setDateFilterEnd: (state, { payload }: PayloadAction<Date>) => {
			state.dateFilterEnd = payload
		},
		setParticipantIds: (state, { payload }: PayloadAction<string[]>) => {
			state.participantIds = payload
		},
		setParticipantId: (state, { payload }: PayloadAction<string | null>) => {
			state.participantId = payload
		},
		setStatus: (state, { payload }: PayloadAction<MeetingStatus | null>) => {
			state.status = payload
		},
		setIsHandoffModalOpen: (state, { payload }: PayloadAction<boolean>) => {
			state.isHandoffModalOpen = payload
		},
		setHandoffMeeting: (state, { payload }: PayloadAction<Meeting | null>) => {
			state.handoffMeeting = payload
		},
		setNewHandoffMeeting: (
			state,
			{ payload }: PayloadAction<Meeting | null>,
		) => {
			state.newHandoffMeeting = payload
		},
		setNewMeetingLead: (
			state,
			{ payload }: PayloadAction<NewMeetingLeadType | null>,
		) => {
			state.newMeetingLead = payload
		},
		setHandoffTimezone: (state, { payload }: PayloadAction<string>) => {
			state.handoffTimezone = payload
		},
	},
})

export const meetingsHomeReducer = meetingsHomeSlice.reducer
export const meetingsHomeActions = meetingsHomeSlice.actions
